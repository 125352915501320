// extracted by mini-css-extract-plugin
export var offerVariant = "OfferVariantList-module--offerVariant--3-wVT";
export var offerVariantContainer = "OfferVariantList-module--offerVariant-container--1tCAU";
export var offerVariantSubheader = "OfferVariantList-module--offerVariant-subheader--CjcGV";
export var offerVariant__footnote = "OfferVariantList-module--offerVariant__footnote--3eIYc";
export var offerVariant__price = "OfferVariantList-module--offerVariant__price--1PiNe";
export var offerVariant__priceDescription = "OfferVariantList-module--offerVariant__price-description--2aI4w";
export var offerVariant__price__suffix = "OfferVariantList-module--offerVariant__price__suffix--3i6-y";
export var offerVariant__list = "OfferVariantList-module--offerVariant__list--1K3P5";
export var offerVariant__listItem = "OfferVariantList-module--offerVariant__list-item--1JDwc";
export var offerVariant__listItemMarker = "OfferVariantList-module--offerVariant__list-item-marker--2SIwQ";
export var offerVariant__button = "OfferVariantList-module--offerVariant__button--vVTeB";
export var offerVariant__arrow = "OfferVariantList-module--offerVariant__arrow--3NVDv";