import React from 'react'
import { Spring } from 'react-spring/renderprops'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'
import PropTypes from 'prop-types'

import { Header } from '../Header'

import * as styles from './AdvantageSection.module.scss'

const AdvantageSection = ({ title, description, ordinalNumber }) => (
  <div className={styles.advantageSection}>
    <Header ordinalNumber={ordinalNumber}>{title}</Header>
    <VisibilitySensorOnce partialVisibility minTopValue={80}>
      {({ isVisible }) => (
        <Spring to={{ opacity: isVisible ? 1 : 0 }}>
          {({ opacity }) => (
            <div style={{ opacity }} className={styles.advantageSectionDescription}>
              {description}
            </div>
          )}
        </Spring>
      )}
    </VisibilitySensorOnce>
  </div>
)

AdvantageSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  ordinalNumber: PropTypes.number.isRequired,
}

export { AdvantageSection }
