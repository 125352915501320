import React, { useCallback, useState } from 'react'
import { OfferFaqDialog } from '../OfferFaqDialog'
import * as styles from '../../templates/oferta-page.module.scss'
import { Header } from '../Header'
import { Button } from '../Button'
import { useKeyPressEvent } from 'react-use'
import throttle from 'lodash/throttle'
import { Container } from '../Container'

export const OfferPageFaqSection = ({ data, className }) => {
  const [open, setOpen] = useState(false)
  const setOpenDialog = useCallback(
    throttle((nextOpen) => {
      setOpen(() => {
        document.body.style.overflow = nextOpen ? 'hidden' : ''
        return nextOpen
      })
    }, 300),
    [setOpen]
  )

  useKeyPressEvent('Escape', () => {
    if (open) {
      setOpenDialog(false)
      document.body.style.overflow = ''
    }
  })

  return (
    <div className={className}>
      <Header primary>Q&A</Header>
      <OfferFaqDialog className={styles.offer__qAndA__dialog} title={<Header>Q&A</Header>} open={open}>
        <Button className={styles.offer__qAndA__dialog__closeButton} onClick={() => setOpenDialog(false)}>
          &#10005;
        </Button>
        <Container className={styles.offer__qAndA__dialog__content}>
          {data.map((faqItem) => (
            <div key={faqItem.question} className={styles.offer__variant}>
              <Header primary>{faqItem.question}</Header>
              {faqItem.answerParts.map((answerPart, idx) => {
                const AnswerPartWrapper = answerPart.type === 'paragraphs' ? React.Fragment : 'ul'
                const AnswerPartItemWrapper = answerPart.type === 'paragraphs' ? 'p' : 'li'

                return (
                  <AnswerPartWrapper key={idx}>
                    {answerPart.items.map((answerPartContent) => (
                      <AnswerPartItemWrapper key={answerPartContent}>{answerPartContent}</AnswerPartItemWrapper>
                    ))}
                  </AnswerPartWrapper>
                )
              })}
            </div>
          ))}
        </Container>
      </OfferFaqDialog>
      <Button className={styles.offer__qAndA__button} onClick={() => setOpenDialog(true)}>
        Dowiedz się więcej
      </Button>
    </div>
  )
}
