import React, { useState, useEffect } from 'react'
import { useSpring, animated, config } from 'react-spring'
import { useMeasure } from 'react-use'
import * as styles from './Accordion.module.scss'

const DEFAULT_HEIGHT = 0

const Accordion = ({ className, children, renderButton, springConfig = { ...config.default, clamp: true } }) => {
  const [open, setOpen] = useState(false)
  const [contentHeight, setContentHeight] = useState(DEFAULT_HEIGHT)
  const [ref, { height }] = useMeasure()

  const contentStyle = useSpring({
    config: springConfig,
    height: `${open ? contentHeight : DEFAULT_HEIGHT}px`,
  })

  useEffect(() => {
    //Sets initial height
    setContentHeight(height)

    //Adds resize event listener
    window.addEventListener('resize', setContentHeight(height))

    // Clean-up
    return window.removeEventListener('resize', setContentHeight(height))
  }, [height])

  return (
    <div className={className}>
      <animated.div className={styles.accordion} style={contentStyle}>
        <div ref={ref}>{children}</div>
      </animated.div>
      {renderButton({ toggle: () => setOpen((prevOpen) => !prevOpen), open })}
    </div>
  )
}

export { Accordion }
