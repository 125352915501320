import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'
import { easeQuadInOut } from 'd3-ease'
import { Spring } from 'react-spring/renderprops'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'

import * as styles from './Header.module.scss'

const Header = ({ ordinalNumber, children, primary }) => (
  <VisibilitySensorOnce partialVisibility="bottom" minTopValue={100}>
    {({ isVisible }) => (
      <h3 className={styles.orderedHeader}>
        {ordinalNumber && (
          <Spring to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }} config={{ mass: 5 }}>
            {({ opacity, scale }) => (
              <span
                style={{ opacity, transform: `scale(${scale})` }}
                className={cx([styles.orderedHeader__number, primary && styles.orderedHeader__numberPrimary])}
              >
                {String(ordinalNumber).padStart(2, '0')}
              </span>
            )}
          </Spring>
        )}
        <Spring delay={200} to={{ opacity: isVisible ? 1 : 0 }} config={{ easing: easeQuadInOut, duration: 300 }}>
          {({ opacity }) => (
            <span
              style={{ opacity }}
              className={cx([styles.orderedHeader__text, primary && styles.orderedHeader__textPrimary])}
            >
              {children}
            </span>
          )}
        </Spring>
      </h3>
    )}
  </VisibilitySensorOnce>
)

Header.propTypes = {
  ordinalNumber: PropTypes.number,
  children: PropTypes.node.isRequired,
  primary: PropTypes.bool,
}

export { Header }
