import React from 'react'
import cx from 'clsx'
import { Spring } from 'react-spring/renderprops'
import { VisibilitySensorOnce } from '../VisibilitySensorOnce'

import { Line } from '../Line'
import { Header } from '../Header'
import { ExpandArrow } from '../Icons/ExpandArrow'
import { Button } from '../Button'
import { Accordion } from '../Accordion'

import { withBreakpoints } from '../../hoc/withBreakpoints'

import * as styles from './OfferVariantList.module.scss'

const OfferVariantListRaw = ({
  headerText,
  subheaderText,
  ordinalNumber,
  dataSet,
  footnote,
  price,
  priceDescription,
}) => (
  <div className={styles.offerVariantContainer}>
    <Header ordinalNumber={ordinalNumber} primary>
      {headerText}
    </Header>
    <div className={cx([styles.offerVariant])}>
      <div className={styles.offerVariantSubheader}>{subheaderText}</div>
      <VisibilitySensorOnce partialVisibility minTopValue={100}>
        {({ isVisible }) => (
          <Spring to={{ opacity: isVisible ? 1 : 0 }}>
            {({ opacity }) => (
              <Accordion
                renderButton={({ toggle, open }) => (
                  <Button
                    hollow={!open}
                    plain={!open}
                    className={styles.offerVariant__button}
                    type="button"
                    onClick={toggle}
                  >
                    {open ? 'Mniej' : 'Więcej'}
                    <ExpandArrow className={styles.offerVariant__arrow} type={open ? 'up' : 'down'} />
                  </Button>
                )}
              >
                <ul className={styles.offerVariant__list} style={{ opacity }}>
                  {dataSet.map((entity) => (
                    <li className={styles.offerVariant__listItem} key={`list-${entity}`}>
                      <div className={styles.offerVariant__listItemMarker}>
                        <Line size="small" reveal />
                      </div>
                      <span>{entity}</span>
                    </li>
                  ))}
                  {footnote && <div className={styles.offerVariant__footnote}>* {footnote}</div>}
                </ul>
                {price && (
                  <div className={styles.offerVariant__price}>
                    {price} zł/m<sup>2</sup>
                    <div className={styles.offerVariant__price__suffix}>brutto</div>
                  </div>
                )}
                {priceDescription && <div className={styles.offerVariant__priceDescription}>{priceDescription}</div>}
              </Accordion>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
    </div>
  </div>
)

const OfferVariantList = withBreakpoints(
  {
    mobile: {
      visibleHeight: 240,
      visibleTranslate: -60,
    },
    tablet: {
      visibleHeight: 160,
      visibleTranslate: -20,
    },
    desktop: {
      visibleHeight: 160,
      visibleTranslate: -20,
    },
  },
  OfferVariantListRaw
)

export { OfferVariantList }
