import React from 'react'
import PropTypes from 'prop-types'
import { Image } from '../Image'

import * as styles from './OfferSliderItem.module.scss'

const OfferSliderItem = ({ imageSrc, imageAlt, onMouseOver, onMouseOut }) => (
  <div className={styles.offerSliderItem} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
    <Image src={imageSrc} alt={imageAlt} responsive dpr="auto" width="auto" crop="scale" />
  </div>
)

OfferSliderItem.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
}

export { OfferSliderItem }
