import React, { useCallback, useEffect, useRef, useState } from 'react'
import Swiper from 'react-id-swiper'
import SwiperCore, { Autoplay, Lazy, Navigation } from 'swiper'
import TransitionLink from 'gatsby-plugin-transition-link'
import { Spring } from 'react-spring/renderprops'
import cx from 'clsx'
import { VisibilitySensorOnce } from '../components/VisibilitySensorOnce'
import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { HeroBanner } from '../components/HeroBanner'
import { OfferVariantList } from '../components/List'
import { UnderlinedHeader } from '../components/UnderlinedHeader'
import { OfferSection } from '../components/OfferSection'
import { AdvantageSection } from '../components/AdvantageSection'
import { OfferSliderItem } from '../components/OfferSliderItem'
import { OfferSliderPaging } from '../components/OfferSliderPaging'
import { Container } from '../components/Container'
import { Button } from '../components/Button'
import { Sketch } from '../components/Icons/Sketch'
import { withBreakpoints } from '../hoc/withBreakpoints'
import { Icon360 } from '../components/Icons/360'
import { Brain } from '../components/Icons/Brain'
import { Box } from '../components/Icons/Box'
import { ParallaxParticles } from '../components/HeroBanner/ParallaxParticles'
import { OfferPageFaqSection } from '../components/OfferPageFaqSection'

import * as styles from './oferta-page.module.scss'
import { graphql } from 'gatsby'

SwiperCore.use([Lazy, Navigation, Autoplay])

const sliderSettings = {
  shouldSwiperUpdate: true,
  autoplay: {
    delay: 5000,
  },
  slidesPerView: 1,
  initialSlide: 0,
  watchSlidesProgress: true,
  loop: true,
}

const OfferPageSlider = ({ isMobile, projectEdges }) => {
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0)
  const [isSliderProgressBarFrozen, setSliderProgressBarFrozen] = useState(false)
  const swiperRef = useRef(null)
  const onSliderArrowClick = useCallback(
    (direction) => {
      if (swiperRef.current !== null && swiperRef.current.swiper !== null) {
        if (direction === 'right') {
          swiperRef.current.swiper.slideNext()
        } else {
          swiperRef.current.swiper.slidePrev()
        }
      }
    },
    [swiperRef]
  )
  const setProgressBarFrozen = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper && swiperRef.current.swiper.autoplay) {
      swiperRef.current.swiper.autoplay.stop()
      setSliderProgressBarFrozen(true)
    }
  }, [swiperRef, setSliderProgressBarFrozen])
  const setProgressBarUnfrozen = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper && swiperRef.current.swiper.autoplay) {
      swiperRef.current.swiper.autoplay.start()
      setSliderProgressBarFrozen(false)
    }
  }, [swiperRef, setSliderProgressBarFrozen])

  const currentSlide = projectEdges[currentSliderIndex] || {}

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      // https://github.com/nolimits4web/swiper/issues/1696
      // https://github.com/nolimits4web/swiper/issues/2942
      swiperRef.current.swiper.on('slideChange', () => {
        const { activeIndex } = swiperRef.current.swiper
        const lastSlideIndex = projectEdges.length - 1

        setSliderProgressBarFrozen(true)
        if (activeIndex > projectEdges.length) {
          // fix looped slide (right)
          setCurrentSliderIndex(0)
        } else if (activeIndex < 1) {
          // fix looped slide (left)
          setCurrentSliderIndex(lastSlideIndex)
        } else {
          setCurrentSliderIndex(activeIndex - 1)
        }
        setTimeout(() => setSliderProgressBarFrozen(false), 0)
      })
    }
  }, [swiperRef, setSliderProgressBarFrozen, setCurrentSliderIndex, projectEdges])

  return (
    <div className={styles.offerSlider}>
      <div className={styles.offerSliderContent}>
        <VisibilitySensorOnce partialVisibility minTopValue={100}>
          {({ isVisible }) => (
            <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 1 : 0 }}>
              {({ opacity }) =>
                isMobile ? (
                  <Swiper ref={swiperRef} {...sliderSettings} style={{ opacity }}>
                    {projectEdges.map((projectEdge, idx) => (
                      <div key={idx}>
                        <OfferSliderItem
                          key={`offer-slider-item-${idx}`}
                          imageSrc={projectEdge.node.frontmatter.featuredImage}
                          imageAlt={projectEdge.node.frontmatter.title}
                          onMouseOver={setProgressBarFrozen}
                          onMouseOut={setProgressBarUnfrozen}
                        />
                      </div>
                    ))}
                  </Swiper>
                ) : (
                  <Container className={styles.offerSliderContainer}>
                    <Swiper ref={swiperRef} {...sliderSettings} style={{ opacity }}>
                      {projectEdges.map((projectEdge, idx) => (
                        <div key={idx}>
                          <OfferSliderItem
                            key={`offer-slider-item-${idx}`}
                            imageSrc={projectEdge.node.frontmatter.featuredImage}
                            imageAlt={projectEdge.node.frontmatter.title}
                            onMouseOver={setProgressBarFrozen}
                            onMouseOut={setProgressBarUnfrozen}
                          />
                        </div>
                      ))}
                    </Swiper>
                  </Container>
                )
              }
            </Spring>
          )}
        </VisibilitySensorOnce>
        <VisibilitySensorOnce partialVisibility minTopValue={100}>
          {({ isVisible }) => (
            <Spring
              from={{ opacity: 0, translate: -40 }}
              to={{ opacity: isVisible ? 1 : 0, translate: isVisible ? 0 : -40 }}
            >
              {({ opacity, translate }) => (
                <div style={{ opacity, transform: `translateX(${translate}px)` }} className={styles.offerSliderOverlay}>
                  <h4 style={{ opacity }} className={styles.offerSliderOverlayHeader}>
                    {currentSlide.node.frontmatter.title}
                  </h4>
                  <TransitionLink
                    style={{ opacity }}
                    exit={{
                      length: 0.6,
                    }}
                    entry={{ delay: 0.4, length: 0.1 }}
                    className={styles.offerSliderOverlayLink}
                    to={currentSlide.node.fields.slug}
                  >
                    ZOBACZ...
                  </TransitionLink>
                </div>
              )}
            </Spring>
          )}
        </VisibilitySensorOnce>
        <Container>
          <VisibilitySensorOnce>
            {({ isVisible }) => (
              <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 1 : 0 }}>
                {({ opacity }) => (
                  <OfferSliderPaging
                    style={{ opacity }}
                    current={currentSliderIndex}
                    total={projectEdges.length}
                    onArrowClick={onSliderArrowClick}
                    freezeProgressBar={isSliderProgressBarFrozen}
                  />
                )}
              </Spring>
            )}
          </VisibilitySensorOnce>
        </Container>
      </div>
    </div>
  )
}

const OfferPageContent = ({ isMobile, frontmatter, projectEdges }) => (
  <>
    <Container>
      <div className={styles.offerPage}>
        {frontmatter.variants.map((variant, idx) => (
          <div key={idx} className={styles.offer__variant}>
            <OfferVariantList
              ordinalNumber={idx + 1}
              headerText={variant.title}
              subheaderText={variant.subtitle}
              dataSet={variant.items}
              footnote={variant.footnote}
              price={variant.price}
              priceDescription={variant.priceDescription}
            />
          </div>
        ))}
        <OfferPageFaqSection className={styles.offer__variant} data={frontmatter.faq} />
      </div>
    </Container>
    <div className={styles.offerSections}>
      <Container>
        <div className={styles.offerSectionsContent}>
          <OfferSection smallIcon title={frontmatter.title360} icon={Icon360}>
            {frontmatter.description360}
          </OfferSection>
          <OfferSection title={frontmatter.titleBrain} icon={Brain}>
            {frontmatter.descriptionBrain}
          </OfferSection>
          <OfferSection title={frontmatter.titleHexagon} icon={Box}>
            {frontmatter.descriptionHexagon}
          </OfferSection>
        </div>
      </Container>
    </div>
    <OfferPageSlider isMobile={isMobile} projectEdges={projectEdges} />
    <div className={styles.offerDownload}>
      <div className={styles.offerDownloadBackground}>
        <ParallaxParticles
          className={cx([styles.offerDownloadBackground__element, styles.offerDownloadBackground__element1])}
        />
        <ParallaxParticles
          className={cx([styles.offerDownloadBackground__element, styles.offerDownloadBackground__element2])}
        />
        <ParallaxParticles
          className={cx([styles.offerDownloadBackground__element, styles.offerDownloadBackground__element3])}
        />
        <ParallaxParticles
          className={cx([styles.offerDownloadBackground__element, styles.offerDownloadBackground__element4])}
        />
        <ParallaxParticles
          className={cx([styles.offerDownloadBackground__element, styles.offerDownloadBackground__element5])}
        />
      </div>
      <VisibilitySensorOnce>
        {({ isVisible }) => (
          <Spring
            from={{ opacity: 0, scale: 0.8 }}
            to={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}
            config={{ mass: 3 }}
          >
            {({ opacity, scale }) => (
              <div style={{ opacity, transform: `scale(${scale})` }} className={styles.offerDownloadIcon}>
                <Sketch />
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
      <VisibilitySensorOnce>
        {({ isVisible }) => (
          <Spring from={{ opacity: 0 }} to={{ opacity: isVisible ? 1 : 0 }}>
            {({ opacity }) => (
              <div style={{ opacity }} className={styles.offerDownloadTitle}>
                <span>{frontmatter.cooperationStages.text1}</span>
                <span>{frontmatter.cooperationStages.text2}</span>
              </div>
            )}
          </Spring>
        )}
      </VisibilitySensorOnce>
      <Button
        className={styles.offerDownloadButton}
        onClick={() => window.open(frontmatter.cooperationStages.fileUrl, '_blank', 'noopener')}
      >
        Pobierz
      </Button>
    </div>
    {frontmatter.benefits.map((benefit) => (
      <Container className={styles.offerAdvantagesWrapper} key={benefit.title}>
        <div className={styles.offerAdvantagesTitle}>
          <UnderlinedHeader underlinePlacement="bottom-center" theme="dark" centered>
            {benefit.title}
          </UnderlinedHeader>
        </div>
        <p className={styles.offerAdvantagesSubtitle}>{benefit.subtitle}</p>
        <div className={styles.offerAdvantages}>
          {benefit.items.map(({ title, text }, idx) => (
            <AdvantageSection key={`benefit-${idx}`} title={title} description={text} ordinalNumber={idx + 1} />
          ))}
        </div>
      </Container>
    ))}
  </>
)

const OfferPage = ({
  isMobile,
  data: {
    markdownRemark: { frontmatter },
    allMarkdownRemark: { edges: projectEdges },
  },
}) => {
  return (
    <Layout darkHero>
      <Seo title="Oferta" description={frontmatter.seoDescription} keywords={frontmatter.seoKeywords} />
      <HeroBanner header={frontmatter.title}>{frontmatter.subtitle}</HeroBanner>
      <OfferPageContent isMobile={isMobile} frontmatter={frontmatter} projectEdges={projectEdges} />
    </Layout>
  )
}

export default withBreakpoints(
  {
    mobile: {
      isMobile: true,
    },
    fallback: {
      isMobile: false,
    },
  },
  OfferPage
)

export const offerPageQuery = graphql`
  query OfferPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        seoDescription
        seoKeywords
        title
        subtitle
        variants {
          title
          subtitle
          items
          footnote
          price
          priceDescription
        }
        faq {
          question
          answerParts {
            type
            items
          }
        }
        title360
        description360
        titleBrain
        descriptionBrain
        titleHexagon
        descriptionHexagon
        cooperationStages {
          fileUrl
          text1
          text2
        }
        benefits {
          title
          subtitle
          items {
            title
            text
          }
        }
      }
    }
    allMarkdownRemark(
      limit: 5
      filter: { frontmatter: { templateKey: { eq: "portfolio-project-page" } } }
      sort: { fields: [frontmatter___createDate], order: [DESC] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            featuredImage
            title
          }
        }
      }
    }
  }
`
