import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import * as styles from './ExpandArrow.module.scss'

const ExpandArrow = ({ type = 'down', primary, className, ...props }) => (
  <svg
    className={cx([
      styles.expandArrow,
      type === 'up' && styles.expandArrowUp,
      primary && styles.expandArrowPrimary,
      className,
    ])}
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      d="M24.285,11.284L16,19.571l-8.285-8.288c-0.395-0.395-1.034-0.395-1.429,0  c-0.394,0.395-0.394,1.035,0,1.43l8.999,9.002l0,0l0,0c0.394,0.395,1.034,0.395,1.428,0l8.999-9.002  c0.394-0.395,0.394-1.036,0-1.431C25.319,10.889,24.679,10.889,24.285,11.284z"
      fill="currentColor"
    />
  </svg>
)

ExpandArrow.propTypes = {
  type: PropTypes.oneOf(['up', 'down']),
  primary: PropTypes.bool,
  className: PropTypes.string,
}

export { ExpandArrow }
