// extracted by mini-css-extract-plugin
export var offerPage = "oferta-page-module--offer-page--2Bp9a";
export var offer__variant = "oferta-page-module--offer__variant--1_bu_";
export var offer__qAndA__button = "oferta-page-module--offer__q-and-a__button--144Wu";
export var offer__qAndA__dialog = "oferta-page-module--offer__q-and-a__dialog--3GppI";
export var offer__qAndA__dialog__content = "oferta-page-module--offer__q-and-a__dialog__content--3s8tn";
export var offer__qAndA__dialog__closeButton = "oferta-page-module--offer__q-and-a__dialog__closeButton--1nZXh";
export var offerSections = "oferta-page-module--offer-sections--1O9IJ";
export var offerSectionsContent = "oferta-page-module--offer-sections-content--3CNu9";
export var offerDownload = "oferta-page-module--offer-download--2zlLs";
export var offerDownloadBackground = "oferta-page-module--offer-download-background--3mLrX";
export var offerDownloadBackground__element = "oferta-page-module--offer-download-background__element--1MkkB";
export var offerDownloadBackground__element1 = "oferta-page-module--offer-download-background__element1--2B5d4";
export var offerDownloadBackground__element2 = "oferta-page-module--offer-download-background__element2--3T1Y9";
export var offerDownloadBackground__element3 = "oferta-page-module--offer-download-background__element3--2S5Xo";
export var offerDownloadBackground__element4 = "oferta-page-module--offer-download-background__element4--1oCRA";
export var offerDownloadBackground__element5 = "oferta-page-module--offer-download-background__element5--21zop";
export var offerDownloadIcon = "oferta-page-module--offer-download-icon--HkMxH";
export var offerDownloadTitle = "oferta-page-module--offer-download-title--3fwmO";
export var offerDownloadButton = "oferta-page-module--offer-download-button--3t6oh";
export var offerAdvantages = "oferta-page-module--offer-advantages--3pMT1";
export var offerAdvantagesWrapper = "oferta-page-module--offer-advantages-wrapper--1NGlC";
export var offerAdvantagesTitle = "oferta-page-module--offer-advantages-title--2SBs-";
export var offerAdvantagesSubtitle = "oferta-page-module--offer-advantages-subtitle--10RnN";
export var offerSlider = "oferta-page-module--offer-slider--2nfZK";
export var offerSliderContainer = "oferta-page-module--offer-slider-container--2K_r_";
export var offerSliderContent = "oferta-page-module--offer-slider-content--2zEEn";
export var offerSliderOverlay = "oferta-page-module--offer-slider-overlay--21tLd";
export var offerSliderOverlayHeader = "oferta-page-module--offer-slider-overlay-header--1k1d8";
export var offerSliderOverlayLink = "oferta-page-module--offer-slider-overlay-link--1v3ea";