import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { animated, config, useSpring } from 'react-spring'
import cx from 'clsx'
import * as styles from './OfferFaqDialog.module.scss'

const portalRoot = typeof document !== `undefined` ? document.getElementById('portal') : null

const OfferFaqDialog = ({ className, title, children, open }) => {
  const [dialogRef, setDialogRef] = useState(null)
  const dialogStyle = useSpring({
    config: config.gentle,
    opacity: open ? 1 : 0,
  })
  const contentStyle = useSpring({
    config: config.stiff,
    delay: 300,
    opacity: open ? 1 : 0,
  })

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setDialogRef(document.createElement('div'))
    }

    return () => {
      if (dialogRef) {
        portalRoot.removeChild(dialogRef)
      }
    }
  }, [])

  useEffect(() => {
    if (dialogRef) {
      portalRoot.appendChild(dialogRef)
    }
  }, [dialogRef])

  return dialogRef
    ? ReactDOM.createPortal(
        open ? (
          <animated.div className={cx(className, styles.offerFaqDialog)} style={dialogStyle}>
            <animated.div style={contentStyle}>{title}</animated.div>
            <animated.div className={styles.offerFaqDialog__content} style={contentStyle}>
              {children}
            </animated.div>
          </animated.div>
        ) : null,
        dialogRef
      )
    : null
}

export { OfferFaqDialog }
