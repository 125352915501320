import React from 'react'
import PropTypes from 'prop-types'
import cx from 'clsx'

import { ArrowIcon } from '../Icons/Arrow'

import * as styles from './OfferSliderPaging.module.scss'

const OfferSliderPaging = ({ current, total, onArrowClick, freezeProgressBar }) => (
  <div className={styles.offerSliderPaging}>
    <div className={styles.offerSliderPagingNumbers}>
      {current + 1} / {total}
    </div>
    <div className={styles.offerSliderPagingProgressWrapper}>
      <div
        className={cx([styles.offerSliderPagingProgress, freezeProgressBar && styles.offerSliderPagingProgressFrozen])}
      />
    </div>
    <div className={styles.offerSliderPagingArrows}>
      <div className={styles.offerSliderPagingArrowWrapper} onClick={() => onArrowClick('left')}>
        <ArrowIcon className={styles.offerSliderPagingArrow} turn="left" />
      </div>
      <div className={styles.offerSliderPagingArrowWrapper} onClick={() => onArrowClick('right')}>
        <ArrowIcon className={styles.offerSliderPagingArrow} turn="right" />
      </div>
    </div>
  </div>
)

OfferSliderPaging.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  onArrowClick: PropTypes.func.isRequired,
}

export { OfferSliderPaging }
